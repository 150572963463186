import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"

const IndexContent = () => {
  const data = useStaticQuery(graphql`
    {
      allIndexPhotosJson {
        edges {
          node {
            image
            alt
          }
        }
      }
    }
  `)
  return <div className = 'index-content'>
    <div className = 'index-content__text'>
      ФОТОПОРТРЕТЫ ПО РЕТРО-ТЕХНОЛОГИЯМ <br/>
      АМБРОТИП (НА СТЕКЛЕ). ТИНТАЙП (НА АЛЮМИНИЕВОЙ ПЛАСТИНЕ)
    </div>
    <div className = 'index-content__picture'>
      <Carousel
        autoPlay
        interval = '3000'
        animationHandler = 'fade'
        infiniteLoop
        >
          {data.allIndexPhotosJson.edges.map (indexPhoto => {
            return <img src = {indexPhoto.node.image} alt = {indexPhoto.node.alt} key = {indexPhoto.node.image}/>
          })}
      </Carousel>
    </div>
  </div>
}


export default IndexContent
